import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getArticle,
  getArticles,
  ArticleList,
} from '../../api/articleManagerAPI';
import { AppThunk } from '../../app/store';

import {
  PrintAudioGetResponse,
  PrintAudioListResponse,
} from '../../common/src/api/types';
import { editArticle } from '../articleForm/editArticleSlice';

interface ArticlesState {
  articlesById: Record<string, ArticleList>;
  fullArticlesById: Record<string, PrintAudioGetResponse>;
  isLoading: boolean;
  error: string | null;
}

const articlesInitialState: ArticlesState = {
  articlesById: {},
  fullArticlesById: {},
  isLoading: false,
  error: null,
};

function startLoading(state: ArticlesState) {
  state.isLoading = true;
}

function loadingFailed(state: ArticlesState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const articles = createSlice({
  name: 'articles',
  initialState: articlesInitialState,
  reducers: {
    getArticleStart: startLoading,
    getArticlesStart: startLoading,
    getArticleSuccess(
      state,
      { payload }: PayloadAction<PrintAudioGetResponse>
    ) {
      const { id } = payload;
      state.fullArticlesById[id] = payload;
      state.isLoading = false;
      state.error = null;
    },
    getArticlesSuccess(
      state,
      { payload }: PayloadAction<{ articles: PrintAudioListResponse }>
    ) {
      const { articles } = payload;
      state.isLoading = false;
      state.error = null;

      state.articlesById = {};
      articles.forEach((article: any) => {
        state.articlesById[article.id] = article;
      });
    },
    getArticleFailure: loadingFailed,
    getArticlesFailure: loadingFailed,
  },
  extraReducers: (builder) => {
    builder.addCase(
      editArticle.actions.deleteArticleSuccess,
      (state, { payload }) => {
        delete state.articlesById[payload];
        delete state.fullArticlesById[payload];
      }
    );
    builder.addCase(
      editArticle.actions.postArticleSuccess,
      (state, { payload }) => {
        const { id, internalName, processor, displayTitle } = payload;
        state.articlesById[id] = { id, internalName, processor, displayTitle };
      }
    );
  },
});

export const {
  getArticleStart,
  getArticlesStart,
  getArticleSuccess,
  getArticlesSuccess,
  getArticleFailure,
  getArticlesFailure,
} = articles.actions;

export default articles.reducer;

export const fetchArticles = (idToken: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(getArticlesStart());
    const articles = await getArticles(idToken);
    dispatch(getArticlesSuccess(articles));
  } catch (err) {
    dispatch(getArticlesFailure(err));
  }
};

export const fetchArticle = (id: string, idToken: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(getArticleStart());
    const article = await getArticle(id, idToken);
    dispatch(getArticleSuccess(article));
  } catch (err) {
    dispatch(getArticleFailure(err));
  }
};

import { combineReducers } from '@reduxjs/toolkit';

import userReducer from '../features/login/userSlice';
import articlesReducer from '../features/articlesList/articlesSlice';
import editArticleReducer from '../features/articleForm/editArticleSlice';
import snackbarReducer from '../components/snackbarSlice';

const rootReducer = combineReducers({
  user: userReducer,
  articles: articlesReducer,
  editArticle: editArticleReducer,
  snackbar: snackbarReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchArticle } from '../articlesList/articlesSlice';
import { RootState } from '../../app/rootReducer';

import { ArticleFormPage } from '../articleForm/ArticleFormPage';
import { CopyButton } from '../../components/CopyButton';
import { addMessage } from '../../components/snackbarSlice';

import { strictEncodeURIComponent } from '../../utils';

interface Props {
  id: string;
}

const PLAYER_DOMAIN = 'https://beta.player.print.audi-on.com';

const CAPITAL_VERSIONS = ['capital_a', 'capital_b'];
const AUFEMININ_VERSIONS = ['aufeminin_a', 'aufeminin_b'];

export const ArticleDetailsPage = ({ id }: Props) => {
  const dispatch = useDispatch();

  const { idToken } = useSelector((state: RootState) => state.user);
  const { fullArticlesById } = useSelector(
    (state: RootState) => state.articles
  );

  const [version, setVersion] = useState('');
  const [previewVersion, setPreviewVersion] = useState('');

  const [isSlim, setIsSlim] = useState(false);

  const article = (fullArticlesById && fullArticlesById[id]) || {};

  const playerUrl = `${PLAYER_DOMAIN}${version}/player.html`;

  useEffect(() => {
    if (idToken) {
      dispatch(fetchArticle(id, idToken));
    }
  }, []);

  const onCopy = (type: string) => {
    const messages: { [k: string]: string } = {
      iframe: 'iframe code copied',
      oembed: 'oembed url copied',
    };

    dispatch(
      addMessage(
        <p className="is-size-7 has-text-weight-bold">{messages[type]}</p>
      )
    );
  };

  const onVersionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;

    setVersion(value);

    switch (value) {
      case '/capital':
        setPreviewVersion(CAPITAL_VERSIONS[1]);
        setIsSlim(false);
        break;
      case '/aufeminin':
        setPreviewVersion(AUFEMININ_VERSIONS[1]);
        setIsSlim(false);
        break;
      case '/jdn':
        setPreviewVersion('');
        setIsSlim(true);
        break;
      default:
        setPreviewVersion('');
        setIsSlim(false);
    }
  };

  const iframeCodeString = fullArticlesById[id]
    ? `<iframe id="player-print-audio" frameborder="0" width="100%" height="${
        isSlim ? '60' : '80'
      }" src="${playerUrl}?id=${
        fullArticlesById[id].id
      }&title=${strictEncodeURIComponent(
        fullArticlesById[id].displayTitle
      )}&url=${strictEncodeURIComponent(
        fullArticlesById[id].contentUrl
      )}"></iframe>`
    : '';

  const oembedUrlString = fullArticlesById[id]
    ? `${PLAYER_DOMAIN}/iframe.html?url=${strictEncodeURIComponent(
        `${playerUrl}?id=${
          fullArticlesById[id].id
        }&title=${strictEncodeURIComponent(
          fullArticlesById[id].displayTitle
        )}&url=${strictEncodeURIComponent(fullArticlesById[id].contentUrl)}`
      )}`
    : '';

  return (
    <>
      <ArticleFormPage article={article} />
      <div className="mt-5 is-flex is-align-items-center">
        <p className="is-size-4 has-text-weight-bold">Player Preview</p>
        {version === '/capital' || version === '/aufeminin' ? (
          <div className="is-flex ml-2">
            {(version === '/capital' ? CAPITAL_VERSIONS : AUFEMININ_VERSIONS).map((version) => (
              <label className="radio is-size-7 ml-4 is-flex has-text-weight-bold">
                <input
                  type="radio"
                  className="mr-2"
                  checked={previewVersion === version}
                  onChange={() => setPreviewVersion(version)}
                />
                {version}
              </label>
            ))}
          </div>
        ) : null}
        <div className="is-flex is-flex-grow-1 is-justify-content-flex-end">
          <div className="select is-small">
            <select onChange={onVersionChange}>
              <option value="">Main Player</option>
              <option value="/aufeminin">Au Feminin Player (AB test)</option>
              <option value="/capital">Capital Player (AB test)</option>
              <option value="/jdn">JDN Player</option>
            </select>
          </div>
        </div>
      </div>
      <iframe
        className="mt-4"
        style={{
          border: '1px solid lightgrey',
        }}
        frameBorder="0"
        width="100%"
        height={isSlim ? '60' : '80'}
        src={
          fullArticlesById[id]
            ? `${playerUrl}?id=${article.id}&title=${strictEncodeURIComponent(
                article.displayTitle
              )}&url=${strictEncodeURIComponent(article.contentUrl)}${
                previewVersion ? `&v=${previewVersion}` : ''
              }`
            : ''
        }
      />
      <p className="is-size-4 has-text-weight-bold mt-5">Player HTML</p>
      <pre className="is-size-7 mt-4" style={{ whiteSpace: 'normal' }}>
        {iframeCodeString}
      </pre>
      <div className="mt-4">
        <CopyButton
          className="button is-primary"
          string={iframeCodeString}
          onClick={() => onCopy('iframe')}
        >
          Copy player html
        </CopyButton>
        <CopyButton
          className="button is-info ml-5"
          string={oembedUrlString}
          onClick={() => onCopy('oembed')}
        >
          Copy oembed url
        </CopyButton>
      </div>
    </>
  );
};

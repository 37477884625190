import * as ttsAPI from '../texttospeach';
export type Title = string;
export type Name = string;
export type Id = string;

export type SearchName = RegExp;
export type SearchTitle = RegExp;
export type SearchId = RegExp;
export type SearchProcessor = RegExp;

// Todo: use enum
export enum Processor {
    GOOGLE = 'GOOGLE',
    MICROSOFT = 'MICROSOFT',
}

export type GoogleRequest = ttsAPI.google.cloud.texttospeech.v1beta1.ISynthesizeSpeechRequest;
export type GoogleResponse = Omit<ttsAPI.google.cloud.texttospeech.v1beta1.ISynthesizeSpeechResponse, 'audioContent'>;

export enum MicrosoftEncoding {
    RAW_16KHZ_16BIT_MONO_PCM = 'raw-16khz-16bit-mono-pcm',
    RAW_8KHZ_8BIT_MONO_MULAW = 'raw-8khz-8bit-mono-mulaw',
    RAW_24KHZ_16BIT_MONO_PCM = 'raw-24khz-16bit-mono-pcm',
    RIFF_8KHZ_8BIT_MONO_ALAW = 'riff-8khz-8bit-mono-alaw',
    RIFF_8KHZ_8BIT_MONO_MULAW = 'riff-8khz-8bit-mono-mulaw',
    RIFF_16KHZ_16BIT_MONO_PCM = 'riff-16khz-16bit-mono-pcm',
    RIFF_24KHZ_16BIT_MONO_PCM = 'riff-24khz-16bit-mono-pcm',
    AUDIO_16KHZ_32KBITRATE_MONO_MP3 = 'audio-16khz-32kbitrate-mono-mp3',
    AUDIO_16KHZ_64KBITRATE_MONO_MP3 = 'audio-16khz-64kbitrate-mono-mp3',
    AUDIO_16KHZ_128KBITRATE_MONO_MP3 = 'audio-16khz-128kbitrate-mono-mp3',
    AUDIO_24KHZ_48KBITRATE_MONO_MP3 = 'audio-24khz-48kbitrate-mono-mp3',
    AUDIO_24KHZ_96KBITRATE_MONO_MP3 = 'audio-24khz-96kbitrate-mono-mp3',
    AUDIO_24KHZ_160KBITRATE_MONO_MP3 = 'audio-24khz-160kbitrate-mono-mp3',
    OGG_24KHZ_16BIT_MONO_OPUS = 'ogg-24khz-16bit-mono-opus',
}

export type MicrosoftRequest = { ssml: string; encoding: MicrosoftEncoding };
export type MicrosoftResponse = MicrosoftRequest;

export type PrintAudioDTO = PrintAudioCreationResponse

export interface PrintAudioCreationRequest {
    displayTitle: Title;
    internalName: Name;
    processor: Processor;
    request: GoogleRequest | MicrosoftRequest;
}

export interface PrintAudioCreationResponse {
    id: Id;
    displayTitle: Title;
    internalName: Name;
    processor: Processor;
    data?: (GoogleResponse & GoogleRequest) | MicrosoftResponse;
}

export interface PrintAudioGetResponse extends PrintAudioDTO {
    contentUrl: string;
}

export type TestContentCreationRequest = {
    processor: Processor;
    request: GoogleRequest | MicrosoftRequest;
};

export interface TestContentCreationResponse {
    testContentUrl: string;
    processor: Processor;
    data?: GoogleResponse | MicrosoftResponse;
}

export type PrintAudioListRequest = {
    searchName?: SearchName;
    searchTitle?: SearchTitle;
    searchId?: SearchId;
    processor?: SearchProcessor;
};

// TODO: return processor
export type PrintAudioListResponse = Array<{
    processor?: Processor;
    displayTitle: Title;
    internalName: Name;
    id: Id;
}>;

import React, { useEffect } from 'react';

interface Props {
  isActive: boolean;
}

export const FullPageLoader = ({ isActive }: Props) => {
  return (
    <div className={`modal ${isActive && 'is-active'}`}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(245, 245, 245, 0.86)' }}
      ></div>
      <div className="modal-content is-flex is-justify-content-center">
        <progress className="progress is-small is-primary" max="100"  style={{ width: '25%' }}></progress>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { emptyMessage, MessageInfo } from './snackbarSlice';
import { RootState } from '../app/rootReducer';

export const Snackbar = () => {
  const dispatch = useDispatch();
  const { message } = useSelector((state: RootState) => state.snackbar);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (message != null) {
      setIsActive(true);
      setTimeout(() => {
        setIsActive(false);
        setTimeout(() => {
          dispatch(emptyMessage());
        }, 250);
      }, 2000);
    }
  }, [message]);

  return (
    <div className="notification-area">
      <div
        className={`notification is-success main-snackbar ${
          isActive && 'is-active'
        }`}
      >
        {message !== null && ((message as unknown) as MessageInfo).content}
      </div>
    </div>
  );
};

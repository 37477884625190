import React, { useEffect, useRef } from 'react';

import { ReactComponent as Logo } from '../../assets/images/audion.svg';

export const LoginPage = () => {
  const loginBtn = useRef(null);

  useEffect(() => {
    window.auth2.attachClickHandler(
      loginBtn.current,
      {},
      function () {
        console.log('success');
      },
      function () {
        console.log('error');
      }
    );
  }, []);

  return (
    <>
      <video autoPlay muted loop id="video-bkg">
        <source src="/bkg.webm" type="video/webm" />
        <source src="/bkg.mp4" type="video/mp4" />
      </video>
      <div
        className="is-flex is-justify-content-center is-align-items-center"
        style={{ height: '100vh' }}
      >
        <div className="card p-6 is-flex is-flex-direction-column">
          <Logo style={{ width: '100%', maxWidth: '420px' }} />
          <div className="is-flex is-justify-content-center mt-6">
            <button className="button is-info" type="button" ref={loginBtn}>
              Sign in with Google
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

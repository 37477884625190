import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setUser } from '../features/login/userSlice';
import { RootState } from './rootReducer';

import { MainPage } from '../components/MainPage';
import { ArticlesListPage } from '../features/articlesList/ArticlesListPage';
import { ArticleFormPage } from '../features/articleForm/ArticleFormPage';
import { ArticleDetailsPage } from '../features/articleDetails/ArticleDetailsPage';
import { LoginPage } from '../features/login/LoginPage';

declare global {
  interface Window {
    googleUser: any;
    onSignIn: any;
    onSignOut: any;
    response: any;
    gapi: any;
    auth2: any;
  }
}

function App() {
  const dispatch = useDispatch();
  const [isAuthDone, setIsAuthDone] = useState(false);
  const { isAuth } = useSelector((state: RootState) => state.user);

  var updateGoogleUser = function () {
    if (window.googleUser) {
      console.log('window.googleUser.getId()', window.googleUser.getId());
      console.log(
        'window.googleUser.getAuthResponse()',
        window.googleUser.getAuthResponse()
      );
    }
  };

  var refreshValues = function () {
    if (window.auth2) {
      console.log('Refreshing values...');

      window.googleUser = window.auth2.currentUser.get();
      console.log(
        'window.googleUser',
        JSON.stringify(window.googleUser, undefined, 2)
      );
      console.log(
        'window.auth2.isSignedIn.get()',
        window.auth2.isSignedIn.get()
      );

      updateGoogleUser();
    }
  };

  const userChanged = function (user: any) {
    console.log('User now: ', user);
    window.googleUser = user;
    updateGoogleUser();

    if (window.googleUser.getAuthResponse().id_token) {
      dispatch(
        setUser({
          name: window.googleUser.getBasicProfile().getName(),
          isAuth: true,
          idToken: window.googleUser.getAuthResponse().id_token,
        })
      );
    }
    setIsAuthDone(true);
  };

  const signinChanged = (val: any) => {
    console.log('Signin state changed to ', val);
  };

  const initSigninV2 = () => {
    window.auth2 = window.gapi.auth2.init({
      client_id:
        '19406460864-8sn3bodem0b57ib8jmjcmvtvo3tuuugl.apps.googleusercontent.com',
      scope: 'profile',
    });
    window.auth2.isSignedIn.listen(signinChanged);

    window.auth2.currentUser.listen(userChanged);

    if (window.auth2.isSignedIn.get() == true) {
      window.auth2.signIn();
    }

    refreshValues();
  };

  const appStart = () => {
    window.gapi.load('auth2', initSigninV2);
  };

  const tryToAppStart = () => {
    if (window.gapi) {
      appStart();
    } else {
      setTimeout(() => {
        tryToAppStart();
      }, 100);
    }
  };

  useEffect(() => {
    tryToAppStart();
  }, []);

  if (isAuthDone) {
    if (!isAuth) {
      return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" render={() => <LoginPage />} />
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <Switch>
            <Redirect exact from="/" to="/articles" />
            <Route exact path="/login" render={() => <LoginPage />} />
            <Route
              path="/articles"
              exact
              render={(props) => {
                return (
                  <MainPage {...props}>
                    <ArticlesListPage />
                  </MainPage>
                );
              }}
            />
            <Route
              path="/articles/create"
              exact
              render={(props) => {
                return (
                  <MainPage {...props}>
                    <ArticleFormPage />
                  </MainPage>
                );
              }}
            />
            <Route
              path="/articles/:articleId"
              exact
              render={(props) => {
                const {
                  match: {
                    params: { articleId },
                  },
                } = props;

                return (
                  <MainPage {...props}>
                    <ArticleDetailsPage id={articleId} />
                  </MainPage>
                );
              }}
            />
            <Route
              render={(props) => {
                return (
                  <MainPage {...props}>
                    <p>404</p>
                  </MainPage>
                );
              }}
            />
          </Switch>
        </BrowserRouter>
      );
    }
  } else {
    return (
      <div
        className="columns is-centered is-vcentered"
        style={{ height: '100vh' }}
      >
        <div className="column is-one-quarter m-6 p-6">
          <p className="has-text-centered is-size-7">Please wait</p>
          <progress
            className="progress is-small is-primary mt-4"
            max="100"
          ></progress>
        </div>
      </div>
    );
  }
}

export default App;

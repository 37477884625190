import React, { useEffect, useState, ReactNode, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchArticles } from './articlesSlice';
import { ArticleList } from '../../api/articleManagerAPI';
import { RootState } from '../../app/rootReducer';

export const ArticlesListPage = () => {
  const dispatch = useDispatch();

  const [filterValue, setFilterValue] = useState('');

  const { idToken } = useSelector((state: RootState) => state.user);
  const { articlesById } = useSelector((state: RootState) => state.articles);

  useEffect(() => {
    if (idToken) {
      dispatch(fetchArticles(idToken));
    }
  }, []);

  const articles = Object.values(
    articlesById
  ).sort(({ internalName: a }, { internalName: b }) => a.localeCompare(b));

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.currentTarget.value);
  };

  const renderArticles = (articles: ArticleList[]): ReactNode => {
    const filter = filterValue.toLowerCase();
    const filteredArticles = filter.length
      ? articles.filter(
          ({ id, internalName }) =>
            internalName.toLowerCase().includes(filter) || id.includes(filter)
        )
      : articles;

    return filteredArticles.map((article) => (
      <li key={article.id}>
        <Link to={`/articles/${article.id}`}>
          <span className="is-size-6">{article.internalName}</span>{' '}
          <span className="is-size-7 has-text-grey">({article.id})</span>
        </Link>
      </li>
    ));
  };

  return (
    <>
      <p className="is-size-4 has-text-weight-bold">Articles</p>
      <div className="columns mt-4 mb-0">
        <div className="column is-half">
          <input
            className="input"
            type="text"
            placeholder="Filter articles by name or id"
            value={filterValue}
            onChange={onFilterChange}
          ></input>
        </div>
        <div className="column is-half is-flex is-justify-content-flex-end">
        <Link className="button is-link" to={`/articles/create`}>
        Create new article
      </Link>
        </div>
      </div>
      <ul>{renderArticles(articles)}</ul>
    </>
  );
};

import {
  PrintAudioCreationRequest,
  TestContentCreationRequest,
} from '../common/src/api/types';

import config from '../config';

export interface ArticleList {
  id: string;
  displayTitle: string;
  internalName: string;
  processor: string;
}

const BASE_URL = `${config.backendUrl}/api`;

export async function getArticles(token: string) {
  const url = `${BASE_URL}/printAudio`;

  try {
    const data = await fetch(url, {
      headers: new Headers({
        'Access-Control-Allow-Origin': true,
        Authorization: `Bearer ${token}`,
      } as any),
    }).then((res) => {
      if (!res.ok) {
        throw res.status;
      }
      return res.json();
    });

    return {
      articles: data,
    };
  } catch (err) {
    throw err;
  }
}

export async function getArticle(id: string, token: string) {
  const url = `${BASE_URL}/printAudio/${id}`;

  try {
    const data = await fetch(url, {
      headers: new Headers({
        'Access-Control-Allow-Origin': true,
        Authorization: `Bearer ${token}`,
      } as any),
    }).then((res) => {
      if (!res.ok) {
        throw res.status;
      }
      return res.json();
    });

    return data;
  } catch (err) {
    throw err;
  }
}

export async function postArticle(
  body: PrintAudioCreationRequest,
  token: string
) {
  const url = `${BASE_URL}/printAudio`;

  try {
    const data = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true,
        Authorization: `Bearer ${token}`,
      } as any),
      body: JSON.stringify(body),
    }).then((res) => {
      if (!res.ok) {
        throw res.status;
      }
      return res.json();
    });

    return data;
  } catch (err) {
    throw err;
  }
}

export async function deleteArticle(id: string, token: string) {
  const url = `${BASE_URL}/printAudio/${id}`;

  try {
    const data = await fetch(url, {
      method: 'DELETE',
      headers: new Headers({
        'Access-Control-Allow-Origin': true,
        Authorization: `Bearer ${token}`,
      } as any),
    }).then((res) => {
      if (!res.ok) {
        throw res.status;
      }
      return res.text();
    });

    return data;
  } catch (err) {
    throw err;
  }
}

export async function getPreview(
  body: TestContentCreationRequest,
  token: string
) {
  const url = `${BASE_URL}/testContent`;

  try {
    const data = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true,
        Authorization: `Bearer ${token}`,
      } as any),
      body: JSON.stringify(body),
    }).then((res) => {
      if (!res.ok) {
        throw res.status;
      }
      return res.json();
    });

    return data;
  } catch (err) {
    throw err;
  }
}

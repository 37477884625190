import React, { useEffect, useState } from 'react';

import { MicrosoftVoiceSelector } from './MicrosoftVoiceSelector';
import { JingleBtn } from './JingleBtn';

import {
  MicrosoftResponse,
  MicrosoftEncoding,
} from '../../common/src/api/types';

interface Props {
  data: MicrosoftResponse;
  onUpdate: (data: MicrosoftResponse) => void;
  disabled?: boolean;
}

const MICROSOFT_ENCODING_OPTIONS = [
  'raw-16khz-16bit-mono-pcm',
  'raw-8khz-8bit-mono-mulaw',
  'raw-24khz-16bit-mono-pcm',
  'riff-8khz-8bit-mono-alaw',
  'riff-8khz-8bit-mono-mulaw',
  'riff-16khz-16bit-mono-pcm',
  'riff-24khz-16bit-mono-pcm',
  'audio-16khz-32kbitrate-mono-mp3',
  'audio-16khz-64kbitrate-mono-mp3',
  'audio-16khz-128kbitrate-mono-mp3',
  'audio-24khz-48kbitrate-mono-mp3',
  'audio-24khz-96kbitrate-mono-mp3',
  'audio-24khz-160kbitrate-mono-mp3',
  'ogg-24khz-16bit-mono-opus',
];

const getTextFromSsml = (text: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(text, 'text/xml');
  const prosody = xmlDoc.querySelector('prosody');
  if (prosody) {
    return prosody.innerHTML.trim();
  }
  return '';
};

const getVoiceFromSsml = (text: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(text, 'text/xml');
  const voice = xmlDoc.querySelector('voice');
  if (voice) {
    return voice.getAttribute('name') || '';
  }
  return '';
};

const getSpeedFromSsml = (text: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(text, 'text/xml');
  const prosody = xmlDoc.querySelector('prosody');
  if (prosody && prosody.getAttribute('rate')) {
    const rate = prosody.getAttribute('rate');
    if (rate) {
      const [value] = rate.split('%');
      return (Number(value) + 100) / 100;
    }
  }
  return 1;
};

const getPitchFromSsml = (text: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(text, 'text/xml');
  const prosody = xmlDoc.querySelector('prosody');
  if (prosody && prosody.getAttribute('pitch')) {
    const pitch = prosody.getAttribute('pitch');
    if (pitch) {
      const [value] = pitch.split('%');
      return (Number(value) + 50) / 50;
    }
  }
  return 1;
};

export const MicrosoftForm = ({ data, onUpdate, disabled = false }: Props) => {
  const [text, setText] = useState<string>(getTextFromSsml(data.ssml));
  const [ssml, setSsml] = useState<string>(data.ssml);
  const [asText, setAsText] = useState<boolean>(!disabled);
  const [voice, setVoice] = useState<string>(getVoiceFromSsml(data.ssml));
  const [speed, setSpeed] = useState<number>(getSpeedFromSsml(data.ssml));
  const [pitch, setPitch] = useState<number>(getPitchFromSsml(data.ssml));
  const [encoding, setEncoding] = useState<MicrosoftEncoding>(data.encoding);

  const getSsmlRate = (rate: number) => {
    return `${Math.round(rate * 100 - 100)}%`;
  };

  const getSsmlPitch = (pitch: number) => {
    return `${Math.round(pitch * 50 - 50)}%`;
  };

  const updateSsml = (ssml: string) => {
    setSsml(ssml);
    setText(getTextFromSsml(ssml));
  };

  const updateText = (text: string) => {
    setText(text);
    setSsml(`<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US">
<voice name="${voice}">
<prosody rate="${getSsmlRate(speed)}" pitch="${getSsmlPitch(pitch)}">
${text}
</prosody>
</voice>
</speak>`);
  };

  useEffect(() => {
    setSsml(`<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US">
<voice name="${voice}">
<prosody rate="${getSsmlRate(speed)}" pitch="${getSsmlPitch(pitch)}">
${text}
</prosody>
</voice>
</speak>`);
  }, [voice, speed, pitch]);

  useEffect(() => {
    onUpdate({
      encoding,
      ssml,
    });
  }, [text, ssml, voice, speed, pitch, encoding]);

  return (
    <>
      <div className="columns">
        <div className="column">
          <div className="field">
            <div className="label is-flex is-align-items-center">
              Text to speak{' '}
              <div className="control ml-5 is-flex">
                <label className="radio is-size-7 is-flex">
                  <input
                    type="radio"
                    disabled={disabled}
                    className="mr-2"
                    checked={asText}
                    onChange={() => {
                      setAsText(true);
                    }}
                  />
                  Text
                </label>
                <label className="radio is-size-7 ml-4 is-flex">
                  <input
                    type="radio"
                    disabled={disabled}
                    className="mr-2"
                    checked={!asText}
                    onChange={() => {
                      setAsText(false);
                    }}
                  />
                  SSML
                </label>
              </div>
              <div className="is-flex is-flex-grow-1 is-justify-content-flex-end">
                <JingleBtn />
              </div>
            </div>
            <div className="control">
              <textarea
                className="textarea"
                style={{height: disabled ? 'auto': '585px'}}
                onChange={(e) => {
                  asText
                    ? updateText(e.currentTarget.value)
                    : updateSsml(e.currentTarget.value);
                }}
                value={asText ? text : ssml}
                disabled={disabled}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <MicrosoftVoiceSelector
          initialValue={voice}
          onChange={setVoice}
          isDisabled={disabled}
        />
      </div>
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">Encoding</label>
            <div className="control">
              <div className="select">
                <select
                  onChange={(e) => {
                    setEncoding(e.currentTarget.value as MicrosoftEncoding);
                  }}
                  value={encoding}
                  disabled={disabled}
                >
                  {MICROSOFT_ENCODING_OPTIONS.map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label is-flex is-justify-content-space-between">
              <span>Speed</span>
              <span className="has-text-weight-normal">{speed}</span>
            </label>
            <div className="control">
              <input
                disabled={disabled}
                onChange={(e) => {
                  setSpeed(Number(e.currentTarget.value));
                }}
                value={speed}
                type="range"
                min="0"
                max="3"
                step="0.01"
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label is-flex is-justify-content-space-between">
              <span>Pitch</span>
              <span className="has-text-weight-normal">{pitch}</span>
            </label>
            <div className="control">
              <input
                disabled={disabled}
                onChange={(e) => {
                  setPitch(Number(e.currentTarget.value));
                }}
                value={pitch}
                type="range"
                min="0"
                max="2"
                step="0.02"
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

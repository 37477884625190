import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  clearPreview,
  createArticle,
  fetchPreview,
  removeArticle,
  resetEditArticle,
} from './editArticleSlice';
import { addMessage } from '../../components/snackbarSlice';
import { RootState } from '../../app/rootReducer';

import { GoogleForm } from './GoogleForm';
import { MicrosoftForm } from './MicrosoftForm';
import { FullPageLoader } from '../../components/FullPageLoader';

import {
  PrintAudioGetResponse,
  Processor,
  GoogleResponse,
  GoogleRequest,
  MicrosoftResponse,
  MicrosoftEncoding,
} from '../../common/src/api/types';

type AudioEncoding = 'LINEAR16' | 'MP3' | 'MP3_64_KBPS' | 'OGG_OPUS' | 'MULAW';

interface Props {
  article?: PrintAudioGetResponse;
}

export const ArticleFormPage = ({ article }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const previewPlayer = useRef<HTMLAudioElement>(null);

  const [isDeletingArticle, setIsDeletingArticle] = useState(false);
  const [isSubmittingArticle, setIsSubmittingArticle] = useState(false);
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const isArticle = useRef(Boolean(article));
  const [title, setTitle] = useState<string>(
    isArticle.current ? (article && article.displayTitle) || '' : ''
  );
  const [name, setName] = useState<string>(
    isArticle.current ? (article && article.internalName) || '' : ''
  );
  const [isGoogle, setIsGoogle] = useState<boolean>(() => {
    if (isArticle.current) {
      if (article && article.processor) {
        return article.processor === Processor.GOOGLE;
      }
    }
    return true;
  });
  const [googleData, setGoogleData] = useState<GoogleResponse & GoogleRequest>(
    () => {
      if (isArticle.current) {
        if (article && article.data) {
          if (article.processor === Processor.GOOGLE) {
            return article.data as GoogleResponse & GoogleRequest;
          }
        }
      }
      return {
        audioConfig: {
          audioEncoding: 'LINEAR16' as AudioEncoding,
          pitch: 0,
          speakingRate: 1,
        },
        input: {
          text: '',
        },
        voice: {
          languageCode: 'fr-FR',
          name: 'fr-FR-Wavenet-A',
        },
      };
    }
  );
  const [microsoftData, setMicrosoftData] = useState<MicrosoftResponse>(() => {
    if (isArticle.current) {
      if (article && article.data) {
        if (article.processor === Processor.MICROSOFT) {
          return article.data as MicrosoftResponse;
        }
      }
    }
    return {
      ssml:
        '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="fr-FR-DeniseNeural"><prosody rate="0%" pitch="0%"></prosody></voice></speak>',
      encoding: 'audio-24khz-96kbitrate-mono-mp3' as MicrosoftEncoding,
    };
  });

  const { idToken } = useSelector((state: RootState) => state.user);
  const { isLoading, error, preview, createdId } = useSelector(
    (state: RootState) => state.editArticle
  );

  const onSubmit = () => {
    previewPlayer.current?.pause();

    if (idToken) {
      dispatch(
        createArticle(
          {
            displayTitle: title,
            internalName: name,
            processor: isGoogle ? Processor.GOOGLE : Processor.MICROSOFT,
            request: isGoogle ? googleData : microsoftData,
          },
          idToken
        )
      );
      setIsSubmittingArticle(true);
    }
  };

  const onDeleteArticle = () => {
    if (article && article.id && idToken) {
      dispatch(removeArticle(article.id, idToken));
      setIsDeletingArticle(true);
      setIsDeleteModalActive(false);
    }
  };

  const onToggleDeleteModal = () => {
    setIsDeleteModalActive(!isDeleteModalActive);
  };

  const onToggleErrorModal = () => {
    setIsErrorModalActive(!isErrorModalActive);
  };

  useEffect(() => {
    dispatch(resetEditArticle());
  }, []);

  useEffect(() => {
    if (article && article.id) {
      const articleIsGoogle = article.processor === Processor.GOOGLE;
      setTitle(article.displayTitle || '');
      setName(article.internalName || '');
      setIsGoogle(articleIsGoogle);
      if (articleIsGoogle) {
        setGoogleData(article.data as GoogleResponse & GoogleRequest);
      } else {
        setMicrosoftData(article.data as MicrosoftResponse);
      }
    }
  }, [article]);

  useEffect(() => {
    if (isGeneratingPreview && !isLoading && error) {
      setIsErrorModalActive(true);
    }
  }, [isLoading, isGeneratingPreview]);

  useEffect(() => {
    if (isSubmittingArticle && !isLoading) {
      if (error === null) {
        history.push(`/articles/${createdId}`);
        dispatch(
          addMessage(
            <>
              Article <b>{name}</b> has been successfully created
            </>
          )
        );
      } else {
        setIsErrorModalActive(true);
      }
    }
  }, [isLoading, isSubmittingArticle]);

  useEffect(() => {
    if (isDeletingArticle && !isLoading) {
      if (error === null) {
        history.push('/articles');
        dispatch(
          addMessage(
            <>
              Article <b>{name}</b> has been successfully deleted
            </>
          )
        );
      } else {
        setIsErrorModalActive(true);
      }
    }
  }, [isLoading, isDeletingArticle]);

  const onGeneratePreview = () => {
    previewPlayer.current?.pause();

    if (idToken) {
      dispatch(clearPreview());
      dispatch(
        fetchPreview(
          {
            processor: isGoogle ? Processor.GOOGLE : Processor.MICROSOFT,
            request: isGoogle ? googleData : microsoftData,
          },
          idToken
        )
      );
      setIsGeneratingPreview(true);
    }
  };

  const canPreview = () => {
    if (isGoogle) {
      if (
        googleData.input &&
        (googleData.input.text || googleData.input.ssml)
      ) {
        return true;
      }
    } else {
      if (microsoftData.ssml) {
        return true;
      }
    }
    return false;
  };

  const canSubmit = () => {
    return name !== '' && title !== '' && canPreview();
  };

  return (
    <>
      <div className="is-flex is-justify-content-space-between is-align-content-center">
        <p className="is-size-4 has-text-weight-bold">
          {isArticle.current ? article?.internalName : 'Create new article'}
        </p>
        {isArticle.current && (
          <button
            type="button"
            className="button is-danger"
            onClick={onToggleDeleteModal}
            style={{ marginTop: '-4px' }}
          >
            Delete
          </button>
        )}
      </div>
      <div className="mt-5">
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label className="label">Internal Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="name"
                  onChange={(e) => {
                    setName(e.currentTarget.value);
                  }}
                  value={name}
                  disabled={isArticle.current}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Display Title</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="title"
                  onChange={(e) => {
                    setTitle(e.currentTarget.value);
                  }}
                  value={title}
                  disabled={isArticle.current}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="label is-flex is-align-items-center">
                Text to speech Engine{' '}
                <div className="control ml-5 is-flex">
                  <label className="radio is-size-7 is-flex">
                    <input
                      type="radio"
                      disabled={isArticle.current}
                      className="mr-2"
                      checked={isGoogle}
                      onChange={() => {
                        setIsGoogle(true);
                      }}
                    />
                    Google
                  </label>
                  <label className="radio is-size-7 ml-4 is-flex">
                    <input
                      type="radio"
                      disabled={isArticle.current}
                      className="mr-2"
                      checked={!isGoogle}
                      onChange={() => {
                        setIsGoogle(false);
                      }}
                    />
                    Microsoft
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isGoogle ? (
          <GoogleForm
            data={googleData}
            onUpdate={setGoogleData}
            disabled={isArticle.current}
          />
        ) : (
          <MicrosoftForm
            data={microsoftData}
            onUpdate={setMicrosoftData}
            disabled={isArticle.current}
          />
        )}
      </div>
      {isArticle.current ? null : (
        <>
          {isGeneratingPreview && preview && (
            <div className="columns">
              <div className="column">
                <p className="is-size-6 has-text-weight-bold mb-2">
                  Audio Preview
                </p>
                <audio
                  ref={previewPlayer}
                  autoPlay
                  src={preview}
                  controls
                  style={{ width: '100%', outline: 'none' }}
                />
              </div>
            </div>
          )}
          <div className="is-flex is-justify-content-center mt-5">
            <button
              type="button"
              className="button is-primary mr-5"
              disabled={!canPreview()}
              onClick={onGeneratePreview}
            >
              Generate Audio Preview
            </button>
            <button
              className="button is-info"
              type="button"
              disabled={!canSubmit()}
              onClick={onSubmit}
            >
              Create
            </button>
          </div>
        </>
      )}
      <div className={`modal ${isDeleteModalActive && 'is-active'}`}>
        <div className="modal-background" onClick={onToggleDeleteModal}></div>
        <div className="modal-content">
          <div className="card p-6">
            <p className="mb-5">
              Are you sure you want to delete <b>{article?.internalName}</b>?
              <br />
              This action is irreversible
            </p>
            <div className="is-flex is-justify-content-flex-end">
              <button className="button is-danger" onClick={onDeleteArticle}>
                Delete this article
              </button>
              <button className="button ml-5" onClick={onToggleDeleteModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${isErrorModalActive && 'is-active'}`}>
        <div className="modal-background" onClick={onToggleErrorModal}></div>
        <div className="modal-content">
          <div className="card p-6">
            <p className="mb-5">
              {isSubmittingArticle && `Creation failed. Error ${error}`}
              {isDeletingArticle && `Deletion failed. Error ${error}`}
              {isGeneratingPreview &&
                `Preview generation failed. Error ${error}`}
            </p>
            <div className="is-flex is-justify-content-flex-end">
              <button className="button ml-5" onClick={onToggleErrorModal}>
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <FullPageLoader isActive={isLoading} />
    </>
  );
};

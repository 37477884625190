import React, { useEffect, useState, useRef } from 'react';

import voices from '../../assets/json/googleVoices.json';
import locales from '../../assets/json/localesToString.json';

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  isDisabled: boolean;
}

export const GoogleVoiceSelector = ({
  initialValue,
  onChange,
  isDisabled = false,
}: Props) => {
  const voicesByLanguageCodes: { [k: string]: any } = {};
  const languageCodesByVoices: { [k: string]: any } = {};
  voices.voices.forEach((voice) => {
    if (!voicesByLanguageCodes[voice.languageCodes[0]]) {
      voicesByLanguageCodes[voice.languageCodes[0]] = {
        label: (locales.locales as { [k: string]: string })[
          voice.languageCodes[0]
        ],
        voices: [],
      };
    }
    voicesByLanguageCodes[voice.languageCodes[0]].voices.push(voice);
    languageCodesByVoices[voice.name] = voice.languageCodes[0];
  });

  const [locale, setLocale] = useState<string>(
    languageCodesByVoices[initialValue]
  );
  const [type, setType] = useState<string>(
    initialValue.includes('Wavenet') ? 'Wavenet' : 'Standard'
  );
  const [isStandardEmpty, setIsStandardEmpty] = useState<boolean>(false);
  const [isWavenetEmpty, setIsWavenetEmpty] = useState<boolean>(false);

  const [name, setName] = useState<string>(initialValue);
  const nameSelect = useRef<HTMLSelectElement>(null);

  const onChangeName = (name: string) => {
    setName(name);
    onChange(name);
  };

  useEffect(() => {
    setLocale(languageCodesByVoices[initialValue]);
    setType(initialValue.includes('Wavenet') ? 'Wavenet' : 'Standard');
    setName(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (
      nameSelect.current &&
      nameSelect.current.value &&
      name !== nameSelect.current.value
    ) {
      onChangeName(nameSelect.current.value);
    }
  }, [locale, type]);

  useEffect(() => {
    if (
      voicesByLanguageCodes[locale].voices.some((voice: { name: string }) =>
        voice.name.includes('Standard')
      )
    ) {
      setIsStandardEmpty(false);
    } else {
      setIsStandardEmpty(true);
      if (type === 'Standard') {
        setType('Wavenet');
      }
    }
    if (
      voicesByLanguageCodes[locale].voices.some((voice: { name: string }) =>
        voice.name.includes('Wavenet')
      )
    ) {
      setIsWavenetEmpty(false);
    } else {
      setIsWavenetEmpty(true);
      if (type === 'Wavenet') {
        setType('Standard');
      }
    }
  }, [locale]);

  return (
    <>
      <div className="column is-half">
        <div className="field">
          <label className="label">Language / locale</label>
          <div className="control">
            <div className="select" style={{ width: '100%' }}>
              <select
                onChange={(e) => {
                  setLocale(e.currentTarget.value);
                }}
                value={locale}
                disabled={isDisabled}
                style={{ width: '100%' }}
              >
                {Object.entries(voicesByLanguageCodes)
                  .sort((a, b) => a[1].label.localeCompare(b[1].label))
                  .map(([key, value]) => (
                    <option key={key} value={key}>
                      {value.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="field">
          <label className="label">Voice type</label>
          <div className="control">
            <div className="select" style={{ width: '100%' }}>
              <select
                onChange={(e) => {
                  setType(e.currentTarget.value);
                }}
                value={type}
                disabled={isDisabled}
                style={{ width: '100%' }}
              >
                <option disabled={isStandardEmpty}>Standard</option>
                <option disabled={isWavenetEmpty}>Wavenet</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="field">
          <label className="label">Voice name</label>
          <div className="control">
            <div className="select" style={{ width: '100%' }}>
              <select
                ref={nameSelect}
                onChange={(e) => onChangeName(e.currentTarget.value)}
                value={name}
                disabled={isDisabled}
                style={{ width: '100%' }}
              >
                {voicesByLanguageCodes[locale].voices
                  .filter((voice: { name: string }) =>
                    voice.name.includes(type)
                  )
                  .sort((a: { name: string }, b: { name: string }) =>
                    a.name.localeCompare(b.name)
                  )
                  .map((voice: { name: string }) => (
                    <option key={voice.name}>{voice.name}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { ReactNode } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MessageInfo {
  content: ReactNode[] | ReactNode;
  key: number;
}

interface SnackbarState {
  message: MessageInfo | null;
}

const SnackbarInitialState = {
  message: null,
};

const snackbar = createSlice({
  name: 'snackbar',
  initialState: SnackbarInitialState,
  reducers: {
    addMessage(
      state: SnackbarState,
      { payload }: PayloadAction<ReactNode[] | ReactNode>
    ) {
      state.message = {
        content: payload,
        key: new Date().getTime(),
      };
    },
    emptyMessage(state: SnackbarState) {
      state.message = null;
    }
  }
});

export const { addMessage, emptyMessage } = snackbar.actions;

export default snackbar.reducer;

import React, { useEffect, useState, useRef } from 'react';

import voices from '../../assets/json/azureVoices.json';
import locales from '../../assets/json/localesToString.json';

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  isDisabled: boolean;
}

export const MicrosoftVoiceSelector = ({
  initialValue,
  onChange,
  isDisabled = false,
}: Props) => {
  const voicesByLanguageCodes: { [k: string]: any } = {};
  const languageCodesByVoices: { [k: string]: any } = {};
  voices.forEach((voice) => {
    if (!voicesByLanguageCodes[voice.Locale]) {
      voicesByLanguageCodes[voice.Locale] = {
        label:
          (locales.locales as { [k: string]: string })[voice.Locale] ||
          voice.Locale,
        voices: [],
      };
    }
    voicesByLanguageCodes[voice.Locale].voices.push(voice);
    languageCodesByVoices[voice.ShortName] = voice.Locale;
  });

  const [locale, setLocale] = useState<string>(
    languageCodesByVoices[initialValue]
  );
  const [type, setType] = useState<string>(
    initialValue.includes('Neural') ? 'Neural' : 'Standard'
  );
  const [isStandardEmpty, setIsStandardEmpty] = useState<boolean>(false);
  const [isNeuralEmpty, setIsNeuralEmpty] = useState<boolean>(false);

  const [name, setName] = useState<string>(initialValue);
  const nameSelect = useRef<HTMLSelectElement>(null);

  const onChangeName = (name: string) => {
    console.log('onChangeName', name);
    setName(name);
    onChange(name);
  };

  useEffect(() => {
    if (
      nameSelect.current &&
      nameSelect.current.value &&
      name !== nameSelect.current.value
    ) {
      onChangeName(nameSelect.current.value);
    }
  }, [locale, type]);

  useEffect(() => {
    if (
      voicesByLanguageCodes[locale].voices.some(
        (voice: { VoiceType: string }) => voice.VoiceType === 'Standard'
      )
    ) {
      setIsStandardEmpty(false);
    } else {
      setIsStandardEmpty(true);
      if (type === 'Standard') {
        setType('Neural');
      }
    }
    if (
      voicesByLanguageCodes[locale].voices.some(
        (voice: { VoiceType: string }) => voice.VoiceType === 'Neural'
      )
    ) {
      setIsNeuralEmpty(false);
    } else {
      setIsNeuralEmpty(true);
      if (type === 'Neural') {
        setType('Standard');
      }
    }
  }, [locale]);

  return (
    <>
      <div className="column is-half">
        <div className="field">
          <label className="label">Language / locale</label>
          <div className="control">
            <div className="select" style={{ width: '100%' }}>
              <select
                onChange={(e) => {
                  setLocale(e.currentTarget.value);
                }}
                value={locale}
                disabled={isDisabled}
                style={{ width: '100%' }}
              >
                {Object.entries(voicesByLanguageCodes)
                  .sort((a, b) => a[1].label.localeCompare(b[1].label))
                  .map(([key, value]) => (
                    <option key={key} value={key}>
                      {value.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="field">
          <label className="label">Voice type</label>
          <div className="control">
            <div className="select" style={{ width: '100%' }}>
              <select
                onChange={(e) => {
                  setType(e.currentTarget.value);
                }}
                value={type}
                disabled={isDisabled}
                style={{ width: '100%' }}
              >
                <option disabled={isStandardEmpty}>Standard</option>
                <option disabled={isNeuralEmpty}>Neural</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="field">
          <label className="label">Voice name</label>
          <div className="control">
            <div className="select" style={{ width: '100%' }}>
              <select
                ref={nameSelect}
                onChange={(e) => onChangeName(e.currentTarget.value)}
                value={name}
                disabled={isDisabled}
                style={{ width: '100%' }}
              >
                {voicesByLanguageCodes[locale].voices
                  .filter(
                    (voice: { VoiceType: string }) => voice.VoiceType === type
                  )
                  .sort(
                    (a: { DisplayName: string }, b: { DisplayName: string }) =>
                      a.DisplayName.localeCompare(b.DisplayName)
                  )
                  .map(
                    (voice: {
                      ShortName: string;
                      DisplayName: string;
                      VoiceType: string;
                    }) => (
                      <option
                        key={voice.ShortName}
                        value={voice.ShortName}
                      >{`${voice.DisplayName}`}</option>
                    )
                  )}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  postArticle,
  deleteArticle,
  getPreview,
} from '../../api/articleManagerAPI';
import { AppThunk } from '../../app/store';

import {
  PrintAudioCreationRequest,
  PrintAudioCreationResponse,
  TestContentCreationRequest,
  TestContentCreationResponse,
} from '../../common/src/api/types';

interface EditArticleState {
  preview: string | null;
  createdId: string | null;
  isLoading: boolean;
  error: string | null;
}

const editArticleInitialState: EditArticleState = {
  preview: null,
  createdId: null,
  isLoading: false,
  error: null,
};

function startLoading(state: EditArticleState) {
  state.isLoading = true;
}

function loadingFailed(state: EditArticleState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const editArticle = createSlice({
  name: 'editArticle',
  initialState: editArticleInitialState,
  reducers: {
    postArticleStart: startLoading,
    deleteArticleStart: startLoading,
    getPreviewStart: startLoading,
    postArticleSuccess(state, { payload }: PayloadAction<PrintAudioCreationResponse>) {
      state.createdId = payload.id;
      state.isLoading = false;
      state.error = null;
    },
    deleteArticleSuccess(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = null;
    },
    getPreviewSuccess(
      state,
      { payload }: PayloadAction<TestContentCreationResponse>
    ) {
      state.preview = payload.testContentUrl;
      state.isLoading = false;
      state.error = null;
    },
    clearPreview(state) {
      state.preview = null;
    },
    resetEditArticle() {
      return editArticleInitialState;
    },
    postArticleFailure: loadingFailed,
    deleteArticleFailure: loadingFailed,
    getPreviewFailure: loadingFailed,
  },
});

export const {
  postArticleStart,
  deleteArticleStart,
  getPreviewStart,
  postArticleSuccess,
  deleteArticleSuccess,
  getPreviewSuccess,
  postArticleFailure,
  deleteArticleFailure,
  getPreviewFailure,
  clearPreview,
  resetEditArticle,
} = editArticle.actions;

export default editArticle.reducer;

export const createArticle = (
  article: PrintAudioCreationRequest,
  idToken: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(postArticleStart());
    const createdArticle = await postArticle(article, idToken);
    dispatch(postArticleSuccess(createdArticle));
  } catch (err) {
    dispatch(postArticleFailure(err));
  }
};

export const removeArticle = (id: string, idToken: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(deleteArticleStart());
    await deleteArticle(id, idToken);
    dispatch(deleteArticleSuccess(id));
  } catch (err) {
    dispatch(deleteArticleFailure(err));
  }
};

export const fetchPreview = (
  article: TestContentCreationRequest,
  idToken: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(getPreviewStart());
    const preview = await getPreview(article, idToken);
    dispatch(getPreviewSuccess(preview));
  } catch (err) {
    dispatch(getPreviewFailure(err));
  }
};

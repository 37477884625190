import React from 'react';
import { useDispatch } from 'react-redux';

import { disconnectUser } from '../features/login/userSlice';
import { Link } from 'react-router-dom';

import { Snackbar } from './Snackbar';

import { ReactComponent as Logo } from '../assets/images/audion.svg';

interface Props {
  children: JSX.Element;
}

export const MainPage = ({ children }: Props) => {
  const dispatch = useDispatch();

  const signOut = () => {
    var auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      dispatch(disconnectUser());
    });
  };

  return (
    <>
      <div
        className="has-background-white"
        style={{ borderBottom: '1px solid #ECECEC' }}
      >
        <div className="container px-4">
          <div className="level p-2 m-0">
            <div className="level-left">
              <div className="level-item">
                <Link to="/">
                  <Logo style={{ height: '40px' }} />
                </Link>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button type="button" className="button" onClick={signOut}>
                  sign out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-4">
        <div className="card p-6">
          <main>{children}</main>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

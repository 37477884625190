import React from 'react';

import { GoogleVoiceSelector } from './GoogleVoiceSelector';
import { JingleBtn } from './JingleBtn';

import { GoogleResponse, GoogleRequest } from '../../common/src/api/types';

type AudioEncoding = 'LINEAR16' | 'MP3' | 'MP3_64_KBPS' | 'OGG_OPUS' | 'MULAW';

interface Props {
  data: GoogleResponse & GoogleRequest;
  onUpdate: (data: GoogleResponse & GoogleRequest) => void;
  disabled?: boolean;
}

const GOOGLE_ENCODING_OPTIONS = [
  'LINEAR16',
  'MP3',
  'MP3_64_KBPS',
  'OGG_OPUS',
  'MULAW',
];

export const GoogleForm = ({ data, onUpdate, disabled = false }: Props) => {
  return (
    <>
      <div className="columns">
        <div className="column">
          <div className="field">
            <div className="label is-flex is-align-items-center">
              Text to speak{' '}
              <div className="control ml-5 is-flex">
                <label className="radio is-size-7 is-flex">
                  <input
                    type="radio"
                    disabled={disabled}
                    className="mr-2"
                    checked={Boolean(data.input && 'text' in data.input)}
                    onChange={() => {
                      if (data.input) {
                        onUpdate({
                          ...data,
                          input: {
                            text: data.input.text || data.input.ssml || '',
                          },
                        });
                      }
                    }}
                  />
                  Text
                </label>
                <label className="radio is-size-7 ml-4 is-flex">
                  <input
                    type="radio"
                    disabled={disabled}
                    className="mr-2"
                    checked={Boolean(data.input && 'ssml' in data.input)}
                    onChange={() => {
                      if (data.input) {
                        onUpdate({
                          ...data,
                          input: {
                            ssml: data.input.text || data.input.ssml || '',
                          },
                        });
                      }
                    }}
                  />
                  SSML
                </label>
              </div>
              <div className="is-flex is-flex-grow-1 is-justify-content-flex-end">
                <JingleBtn />
              </div>
            </div>
            <div className="control">
              <textarea
                className="textarea"
                style={{height: disabled ? 'auto': '585px'}}
                onChange={(e) => {
                  if (data.input) {
                    onUpdate({
                      ...data,
                      input: {
                        ['ssml' in data.input ? 'ssml' : 'text']: e
                          .currentTarget.value,
                      },
                    });
                  }
                }}
                value={data.input?.text || data.input?.ssml || ''}
                disabled={disabled}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <GoogleVoiceSelector
          initialValue={data.voice?.name || 'fr-FR-Wavenet-A'}
          onChange={(name: string) => {
            onUpdate({
              ...data,
              voice: {
                languageCode: name.substring(0, 5),
                name,
              },
            });
          }}
          isDisabled={disabled}
        />
      </div>
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">Encoding</label>
            <div className="control">
              <div className="select">
                <select
                  onChange={(e) => {
                    if (data.audioConfig) {
                      onUpdate({
                        ...data,
                        audioConfig: {
                          ...data.audioConfig,
                          audioEncoding: e.currentTarget.value as AudioEncoding,
                        },
                      });
                    }
                  }}
                  value={
                    data.audioConfig?.audioEncoding ||
                    ('LINEAR16' as AudioEncoding)
                  }
                  disabled={disabled}
                >
                  {GOOGLE_ENCODING_OPTIONS.map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label is-flex is-justify-content-space-between">
              <span>Speed</span>
              <span className="has-text-weight-normal">
                {data.audioConfig?.speakingRate || 1}
              </span>
            </label>
            <div className="control">
              <input
                disabled={disabled}
                onChange={(e) => {
                  if (data.audioConfig) {
                    onUpdate({
                      ...data,
                      audioConfig: {
                        ...data.audioConfig,
                        speakingRate: Number(e.currentTarget.value),
                      },
                    });
                  }
                }}
                value={data.audioConfig?.speakingRate || 1}
                type="range"
                min="0.25"
                max="4"
                step="0.01"
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label is-flex is-justify-content-space-between">
              <span>Pitch</span>
              <span className="has-text-weight-normal">
                {data.audioConfig?.pitch || 0}
              </span>
            </label>
            <div className="control">
              <input
                disabled={disabled}
                onChange={(e) => {
                  if (data.audioConfig) {
                    onUpdate({
                      ...data,
                      audioConfig: {
                        ...data.audioConfig,
                        pitch: Number(e.currentTarget.value),
                      },
                    });
                  }
                }}
                value={data.audioConfig?.pitch || 0}
                type="range"
                min="-20"
                max="20"
                step="1"
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

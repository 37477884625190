const config = {
  local: {
    backendUrl: 'http://localhost:3000'
  },
  production: {
    backendUrl: 'https://beta.api.print.audi-on.com'
  }
};

if (!process.env.REACT_APP_SERVICE_ENV) {
  throw new Error('SERVICE_ENV is undefined.');
}

type Env = 'production' | 'local';
export default config[process.env.REACT_APP_SERVICE_ENV as Env];

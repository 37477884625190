import React from 'react';
import { useDispatch } from 'react-redux';

import { addMessage } from '../../components/snackbarSlice';

import { CopyButton } from '../../components/CopyButton';

const JINGLE_CODE =
  '<audio src="https://beta.content.print.audi-on.com/note3.wav"> Jingle </audio>';

export const JingleBtn = () => {
  const dispatch = useDispatch();

  const onCopyJingle = () => {
    dispatch(
      addMessage(
        <p className="is-size-7 has-text-weight-bold">Jingle code copied</p>
      )
    );
  };

  return (
    <CopyButton
      className="button is-primary is-small"
      string={JINGLE_CODE}
      onClick={onCopyJingle}
    >
      Copy Jingle Code
    </CopyButton>
  );
};

import React, { useRef, ReactNode } from 'react';

interface Props {
  children: ReactNode[] | ReactNode;
  string: string;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}

export const CopyButton = ({ children, string, onClick, className, disabled = false }: Props) => {
  const ref = useRef<HTMLButtonElement>(null);

  function handleClick() {
    setToCopy();
    onClick();
  }
  function setToCopy() {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.value = string;
    ref.current?.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    ref.current?.removeChild(textArea);
  }

  return (
    <button type="button" onClick={handleClick} className={className} disabled={disabled} ref={ref}>
      {children}
    </button>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../../app/store';

interface UserState {
  name: string;
  isAuth: boolean;
  idToken: string | null,
}

const userInitialState: UserState = {
  name: '',
  isAuth: false,
  idToken: null,
};

function handleUnauthorized(
  state: UserState,
  { payload }: PayloadAction<Error>
) {
  state.isAuth = false;
}

export const user = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setUser(state, { payload }) {
      state.name = payload.name;
      state.isAuth = payload.isAuth;
      state.idToken = payload.idToken;
    },
  },
});

export const { setUser } = user.actions;

export default user.reducer;

export const disconnectUser = (): AppThunk => (dispatch) => {
  dispatch(setUser(userInitialState));
};
